import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { SyntheticEvent, useEffect, useMemo } from 'react';

import {
  BO_CONSECUTIVE,
  BO_FRONT_AND_BACK,
  BO_FRONT_AND_BACK_CONSECUTIVE,
  BO_FRONT_AND_BACK_PARALLEL,
  BO_PARALLEL,
  BookingData,
  BookingType,
  BookingsOrder,
  Slot,
} from 'common';

import { MAX_PLAYERS_PER_SLOT } from '../../BookingsData';
import { hasGroupBookingInBulkSelection } from '../../helpers/bookingsHelper';
import { checkTotalPlayersValid } from './Validator';

export type PlayerDetailsData = Pick<
  BookingData,
  | 'players'
  | 'infantPlayers'
  | 'kidPlayers'
  | 'adultPlayers'
  | 'seniorPlayers'
  | 'totalPlayers'
  | 'bookingsOrder'
  | 'slot'
  | 'bookingType'
>;
export type SetPlayerDetailsData = (data: PlayerDetailsData) => void;

export interface PlayerDetailsProps {
  data: PlayerDetailsData;
  setData: SetPlayerDetailsData;
  isEditing: boolean;
  isGroupBooking: boolean;
  saveAsGroupBooking: boolean;
  saveAsFrontAndBackBooking: boolean;
  slotCount: number;
  setSlotCount: (value: number) => void;
  shouldBulkEdit: boolean;
  selectedForBulkEdit: BookingData[];
}

interface PlayerCountProps {
  data: PlayerDetailsData;
  setData: SetPlayerDetailsData;
  disabled: boolean;
  playerType: 'infantPlayers' | 'kidPlayers' | 'adultPlayers' | 'seniorPlayers';
  label: string;
  valid: boolean;
}

export function PlayerDetails(props: PlayerDetailsProps) {
  const {
    data,
    setData,
    isEditing,
    isGroupBooking,
    saveAsGroupBooking,
    saveAsFrontAndBackBooking,
    slotCount,
    setSlotCount,
    shouldBulkEdit,
    selectedForBulkEdit,
  } = props;

  const totalPlayersIsValid = useMemo(
    () => checkTotalPlayersValid(data.totalPlayers, shouldBulkEdit, selectedForBulkEdit),
    [data.totalPlayers, shouldBulkEdit, selectedForBulkEdit],
  );

  useEffect(() => {
    const slotMultiplier = saveAsFrontAndBackBooking ? 2 : 1;
    setSlotCount(Math.ceil(data.totalPlayers / MAX_PLAYERS_PER_SLOT) * slotMultiplier);
  }, [saveAsFrontAndBackBooking, data.totalPlayers, setSlotCount]);

  // We need to check if any of the selected booking in bulk edit mode has a group booking
  const groupBookingInBulkSelection = hasGroupBookingInBulkSelection(selectedForBulkEdit);

  // We'll make these fields disabled when,
  // not isEditing or
  // saving only a single group booking or
  // there is a group booking selected when in bulk edit mode.
  const disableFields =
    !isEditing ||
    (isGroupBooking && !saveAsGroupBooking) ||
    (shouldBulkEdit && groupBookingInBulkSelection);

  const handleBookingsOrderChange = (event: SelectChangeEvent) => {
    setData({ ...data, bookingsOrder: event.target.value as BookingsOrder });
  };

  let bookingOrders: BookingsOrder[] = [];

  if (data.bookingType === BookingType.FrontAndBack) {
    bookingOrders = [BO_FRONT_AND_BACK];
  } else if (data.bookingType === BookingType.GroupFrontAndBack) {
    bookingOrders = [BO_FRONT_AND_BACK_CONSECUTIVE, BO_FRONT_AND_BACK_PARALLEL];
  } else if (data.bookingType === BookingType.Group) {
    // No parallel bookings are allowed for Mini slots
    bookingOrders = data.slot === Slot.Mini ? [BO_CONSECUTIVE] : [BO_CONSECUTIVE, BO_PARALLEL];
  }

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item md={12}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md xl={2}>
              <PlayerCount
                data={data}
                setData={setData}
                disabled={disableFields}
                valid={totalPlayersIsValid}
                playerType="adultPlayers"
                label="Adults"
              ></PlayerCount>
            </Grid>
            <Grid item xs={12} md xl={2}>
              <PlayerCount
                data={data}
                setData={setData}
                disabled={disableFields}
                valid={totalPlayersIsValid}
                playerType="kidPlayers"
                label="Kids (Ages 5 - 14)"
              ></PlayerCount>
            </Grid>
            <Grid item xs={12} md xl={2}>
              <PlayerCount
                data={data}
                setData={setData}
                disabled={disableFields}
                valid={totalPlayersIsValid}
                playerType="infantPlayers"
                label="Kids (Under 4)"
              ></PlayerCount>
            </Grid>
            <Grid item xs={12} md xl={2}>
              <PlayerCount
                data={data}
                setData={setData}
                disabled={disableFields}
                valid={totalPlayersIsValid}
                playerType="seniorPlayers"
                label="Seniors"
              ></PlayerCount>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md xl={2}>
              <FormControl fullWidth>
                <TextField disabled={true} label="Total Players" value={data.totalPlayers} />
              </FormControl>
            </Grid>
            <Grid item xs={12} md xl={2}>
              <FormControl fullWidth>
                <TextField disabled={true} label="Number of slots" value={slotCount} />
              </FormControl>
            </Grid>
            {data.bookingType !== BookingType.Single && (
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="bookings-order-label">Bookings Order</InputLabel>
                  <Select
                    labelId="bookings-order-label"
                    label="Bookings Order"
                    value={data.bookingsOrder}
                    disabled={disableFields}
                    onChange={handleBookingsOrderChange}
                  >
                    {bookingOrders.map((bookingOrder) => (
                      <MenuItem key={bookingOrder} value={bookingOrder}>
                        {bookingOrder}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const PlayerCount = (props: PlayerCountProps) => {
  const { data, setData, disabled, valid, playerType, label } = props;

  const numberOptions = [
    { label: '0', value: 0 },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
  ];

  // eslint-disable-next-line security/detect-non-literal-regexp
  const regex = new RegExp(playerType);

  const handlePlayerChange = (event: SyntheticEvent, value: string) => {
    const matches = event.currentTarget.id.match(regex);
    const playerType = matches ? matches[0] : '';
    const playerCount = parseInt(value, 10);

    setData({
      ...data,
      [playerType]: isNaN(playerCount) ? 0 : playerCount,
    });
  };

  return (
    <FormControl fullWidth>
      <Autocomplete
        id={playerType}
        freeSolo
        disableClearable
        options={numberOptions}
        disabled={disabled}
        inputValue={data[playerType].toString()}
        onInputChange={handlePlayerChange}
        filterOptions={() => numberOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            type="number"
            error={!valid}
            inputProps={{
              ...params.inputProps,
              min: 0,
            }}
          />
        )}
      />
    </FormControl>
  );
};
