import { FormControl, Grid } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';

import {
  BookingData,
  Hole,
  Slot,
  SlotNameTable,
  isFridayOrSaturday,
  isFrontAndBackSlot,
  isNotZero,
} from 'common';

import { DEFAULT_SLOT_GAP, SlotDescriptionLookup, getSlotFromKey } from '../../BookingsData';
import { hasLinkedBookingInBulkSelection } from '../../helpers/bookingsHelper';
import { UseBookingType } from '../../utils/hooks/useBookingType';
import { UseFrontAndBackBooking } from '../../utils/hooks/useFrontAndBackBooking';
import { isHolidaysOrWeekend } from '../header/holidayHelper';
import { FrontAndBackOtherSlotTimes } from './FrontAndBackOtherSlotTime';

enum SlotCombination {
  All,
  FrontSlotAndBackSlot,
  FrontAndBackSlotOnly,
  NoFrontAndBackSlot,
}

export interface SlotDetailsProps {
  data: BookingData;
  setData: (data: BookingData) => void;
  isEditing: boolean;
  shouldBulkEdit: boolean;
  selectedForBulkEdit: BookingData[];
  frontAndBackBooking: UseFrontAndBackBooking;
  bookingType: UseBookingType;
  isNewBooking: boolean;
  keepLink: boolean;
}

export const SlotDetails = ({
  data,
  setData,
  isEditing,
  shouldBulkEdit,
  selectedForBulkEdit,
  frontAndBackBooking,
  bookingType,
  isNewBooking,
  keepLink,
}: SlotDetailsProps) => {
  const { isFrontAndBackBooking, isGroupBooking } = bookingType;

  const clickedSlot = getSlotFromKey(data);

  // We need to check if any of the selected booking in bulk edit mode has a linked booking
  const linkedBookingInBulkSelection = hasLinkedBookingInBulkSelection(selectedForBulkEdit);

  const handleDateUpdate = async (value: Dayjs | null) => {
    if (!value) return;

    setData({
      ...data,
      slotDate: value.toDate(),
    });
  };

  const handleTimeUpdate = async (value: dayjs.Dayjs | null) => {
    if (!value) return;

    // TODO to-deprecate
    // Round to the nearest 5
    const offset = value.minute() % 5;
    if (isNotZero(offset)) {
      if (offset > 2) {
        value = value.add(5 - offset, 'minute');
      } else {
        value = value.add(-1 * offset, 'minute');
      }
    }

    setData({
      ...data,
      slotDate: value.toDate(),
    });
  };

  const handleSlotUpdate = async (event: SelectChangeEvent) => {
    const value = parseInt(event.target.value, 10);
    const slot = SlotNameTable[Slot[value]];

    setData({
      ...data,
      slot,
      hole: isFrontAndBackSlot(slot) ? Hole.Eighteen : data.hole,
    });
  };

  const slotCombination = getSlotCombination(
    isNewBooking,
    isFrontAndBackBooking,
    isGroupBooking,
    keepLink,
    shouldBulkEdit,
    linkedBookingInBulkSelection,
    clickedSlot,
  );

  const minTime: Dayjs = isHolidaysOrWeekend(data.slotDate)
    ? dayjs('8:00AM', 'h:mmA')
    : dayjs('10:00AM', 'h:mmA');
  let maxTime: Dayjs;
  if (data.slot === Slot.Mini) {
    maxTime = isFridayOrSaturday(dayjs(data.slotDate))
      ? dayjs('7:30PM', 'h:mmA')
      : isHolidaysOrWeekend(data.slotDate)
        ? dayjs('4:30PM', 'h:mmA')
        : dayjs('3:00PM', 'h:mmA');
  } else {
    maxTime = isHolidaysOrWeekend(data.slotDate)
      ? dayjs('4:00PM', 'h:mmA')
      : dayjs('2:30PM', 'h:mmA');
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <DatePicker
                label="Day"
                format="DD/MM/YYYY"
                value={dayjs(data.slotDate)}
                disabled={!isEditing}
                onChange={handleDateUpdate}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TimePicker
                label="Time"
                // Disable it during bulk edit
                disabled={!isEditing || shouldBulkEdit}
                minTime={minTime}
                maxTime={maxTime}
                value={dayjs(data.slotDate)}
                ampm={false}
                onChange={handleTimeUpdate}
                minutesStep={DEFAULT_SLOT_GAP}
                skipDisabled
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="slot-select-label">Slot</InputLabel>
              <Select
                labelId="slot-select-label"
                label="Slot"
                value={data.slot.toString()}
                disabled={!isEditing || linkedBookingInBulkSelection}
                onChange={handleSlotUpdate}
              >
                {slotCombinationToSlotsMap[slotCombination].map((slot) => (
                  <MenuItem key={slot.toString()} value={slot}>
                    {SlotDescriptionLookup[slot]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {isFrontAndBackBooking && (
            <Grid item xs={12}>
              <FrontAndBackOtherSlotTimes
                isEditing={isEditing}
                frontAndBackBooking={frontAndBackBooking}
              />
            </Grid>
          )}
        </Grid>
      </LocalizationProvider>
    </>
  );
};

const getSlotCombination = (
  isNewBooking: boolean,
  isFrontAndBackBooking: boolean,
  isGroupBooking: boolean,
  keepLink: boolean,
  shouldBulkEdit: boolean,
  linkedBookingInBulkSelection: boolean,
  clickedSlot: Slot,
) => {
  if (isNewBooking) {
    return SlotCombination.All;
  }

  if (isFrontAndBackBooking) {
    return isGroupBooking || keepLink
      ? SlotCombination.FrontAndBackSlotOnly
      : SlotCombination.FrontSlotAndBackSlot;
  }

  if (
    isGroupBooking ||
    clickedSlot === Slot.Mini ||
    (shouldBulkEdit && linkedBookingInBulkSelection)
  ) {
    return SlotCombination.NoFrontAndBackSlot;
  }

  return SlotCombination.All;
};

const slotCombinationToSlotsMap: Record<SlotCombination, Slot[]> = {
  [SlotCombination.All]: [Slot.Front, Slot.Back, Slot.Mini, Slot.FrontAndBack],
  [SlotCombination.FrontSlotAndBackSlot]: [Slot.Front, Slot.Back],
  [SlotCombination.FrontAndBackSlotOnly]: [Slot.FrontAndBack],
  [SlotCombination.NoFrontAndBackSlot]: [Slot.Front, Slot.Back, Slot.Mini],
};
